<template>
  <div class="w-content">
    <form @submit.prevent="createToken">
      <h2 class="title">{{ $t("CreateToken_h2_2") }}</h2>
      <div class="mb-20">{{ $t("CreateToken_p_2") }}</div>
      <p>Your token's full name</p>
      <input class="main-input" placeholder="Name" id="SmartName" v-model.trim="smartName" />
      <span class="textColorRed"
            id="ErrorSmartName"
            v-if="$v.smartName.$dirty && !$v.smartName.required">Name field must not be empty</span>
      <p>Ticker symbol (abbreviated name or label of the token)</p>
      <input class="main-input" placeholder="Symbol" id="Symbol" v-model.trim="symbol" />
      <span class="textColorRed"
            id="ErrorSymbol"
            v-if="$v.symbol.$dirty && !$v.symbol.required">Symbol field must not be empty</span>
      <p>The number of characters after the dot (not more than 18 digits)</p>
      <input class="main-input" placeholder="Decimal" id="Decimal" v-model.trim="decimal" />
      <span class="textColorRed"
            id="ErrorDecimal"
            v-if="$v.decimal.$dirty && !$v.decimal.required">Decimal field must not be empty</span>
      <p>Number of tokens (no more than 27 digits)</p>
      <input class="main-input" placeholder="Total quantity" id="TotalCoins" v-model.trim="amount" />
      <span class="textColorRed"
            id="ErrorAmount"
            v-if="$v.amount.$dirty && !$v.amount.required">Total quantity field must not be empty</span>
      <div class="mb-50">
        You can add the functionality of your token to an additional number of parameters not listed here. To do this,
        you will need to write them in the source code of the smart contract.
        <a
          href="https://developers.credits.com/en/Articles/a_Creating_tokens_smart_contract_on_the_Credits_standard"
          class="link"
          target="_blank"
        >Read more</a>
      </div>
      <div class="row mb-50">
        <router-link :to="`/${$i18n.locale}/wallet/perform-transaction`" class="link fo_link">Back</router-link>
        <button type="submit" class="bold-link bttn margin_left_60">Create contract</button>
      </div>
    </form>
  </div>
</template>
<script>
  import {mapState} from "vuex";
  import i18n from "@/i18n";
  import {required} from "vuelidate/lib/validators";

  export default {
  data() {
    return {
      smartName: "",
      symbol: "",
      decimal: "",
      methodApi: "SmartDeploy",
      sourceSmart: "",
      dec: 0,
      amount: 0
    };
  },

  validations: {
    smartName: {required},
    symbol: {
      required
    },
    decimal: {required},
    amount: {required}
  },

  methods: {
    createToken: function() {

      if (this.$v.$invalid === true) {
        this.$v.$touch();
        console.log("adress is required");
        return;
      }

      this.$loading(true);
      let _amount = this.amount.toString().replace(',','.');
      let tokenData = {
        smartName: this.smartName,
        symbol: this.symbol,
        dec: parseInt(this.decimal),
        methodApi: this.methodApi,
        amount: parseFloat(_amount)
      };

      console.log("TOKEN DATA:");
      console.log(tokenData);
      console.log("SOURCE:");
      console.log(this.sourceSmart);

      this.$store.commit("SET_createSmartData", tokenData);
      this.$router.push(`/${i18n.locale}/wallet/create-smart`);
    }
  },
  mounted() {
    this.smartName = this.createSmartData.smartName;
    this.symbol = this.createSmartData.symbol;
    this.dec = this.createSmartData.dec;
    this.amount = this.createSmartData.amount;
  },
  destroyed() {
    this.$loading(false);
  },
  computed: {
    ...mapState({
      publicKey: s => s.publicKey,
      privateKey: s => s.privateKey,
      createTokenData: s => s.createSmartData
    })
  }
};
</script>
